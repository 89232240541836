@font-face {
    font-family: "GothamBlack";
    src: local("GothamBlack"), url("./fonts/Gotham-Black.otf");
}

@font-face {
    font-family: "GothamBook";
    src: local("GothamBook"), url("./fonts/Gotham Book.otf");
}

@font-face {
    font-family: "GothamRoundedBold";
    src: local("GothamRoundedBold"), url("./fonts/GothamRounded-Bold.otf");
}

@font-face {
    font-family: "GothamRoundedMedium";
    src: local("GothamRoundedMedium"), url("./fonts/GothamRounded-Medium.otf");
}

.gotham-black{
    font-family: GothamBlack;
}
.gotham-book{
    font-family: GothamBook;
}
.gotham-rounded-bold{
    font-family: GothamRoundedBold;
}
.gotham-rounded-medium{
    font-family: GothamRoundedMedium;
}

.ant-popover-arrow-content{
    background-color: #E02020 ;
}

.ant-popover-arrow-content::before{
    background: linear-gradient(to left, #E02020 50%, #E02020 50%) no-repeat -10px -10px ;
}

.ant-popover-inner-content{
    color: white !important;
    font-family: GothamRoundedMedium;
}
.ant-popover-inner{
    border-radius: 5px !important;
}

.close-icon{
    margin-left: 20px;
}

.alert-icon{
    margin-right: 10px;
}

.ant-spin-container::after{
    background: black;
}

.ant-collapse-borderless{
    background: linear-gradient(135deg,rgba(37,59,96,1) 0%,rgba(14,14,0,1) 88%);
    border: 1px solid white;
}

.ant-collapse{
    border-radius: 25px;
    width: 90vw;
    max-width: 480px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    transform: translateX(26rem);
    font-size: 25px;
    color: white;
}

@media only screen and (max-width: 500px){
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
        transform: translateX(23rem);
    }
}

@media only screen and (max-width: 450px){
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
        transform: translateX(21rem);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        width: 300px;
    }
}

@media only screen and (max-width: 400px){
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
        transform: translateX(19rem);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        width: 300px;
    }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    color: white;
    font-size: 16px;
    padding: 12px 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box > div > p{
    color:white;
    margin-left:20px;
}

.ant-collapse-borderless > .ant-collapse-item > span > svg{
    transform: rotate(180deg);
}

.ant-card-head{
    border-bottom: none;
}

.ant-card-head-title{
    font-size: 22px;
    font-family: Gotham, sans-serif;
    color: white;
    text-align: center;
}

.card-container-card{
    width: 90vw;
    max-width: 400px;
    border-radius: 25px;
    background: rgb(255,250,250);
    background: linear-gradient(315deg, rgba(255,250,250,0.16) 0%, rgba(255,252,252,0.11) 70%, rgba(255,255,255,0.015) 100%);
}

.ant-tabs{
    width: 90vw;
}

.ant-tabs-tab{
    display: flex;
    justify-content: center;
    width: 45vw;
    color: white;
}

.ant-tabs-tab:hover, .ant-tabs-tab-btn:focus{
    color: white !important;
}

.ant-tabs-tabpane{
    display: flex;
    justify-content: center;
}

.ant-tabs-tab + .ant-tabs-tab{
    margin: 0 !important;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar{
    height: 5px;
}

.ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: 1px solid white !important;
    background-color: white;
    height: 5px;
}

.ant-popover-arrow-content::before{
    background: white;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{
    transform: rotate(270deg);
}


/*.ant-carousel .slick-dots li.slick-active button{
    width: 18px !important;
    height: 18px !important;
}

.ant-carousel .slick-dots li button{
    width: 14px !important;
    height: 14px !important;
}*/
